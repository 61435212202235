import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Content from "../../components/Content"
import Gallery from "../../components/Gallery"

const galImgs = ['/page-images/img_1040.jpg','/page-images/img_0461.jpg', '/page-images/img_1002_0.jpg']

export default function SiteOps() {
  return (
    <Layout breadcrumbs={["Site Operations"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content>
<p><strong>Site Operations</strong></p>
<p>	Once the planning and building control approvals are obtained, the project can then be issued to an agreed select list of building contractors for competitive pricing.  We, or in conjunction with a quantity surveyor, assess the validity and value of the lowest tender returns and advise our clients accordingly.</p>
<p>	The selected contractor is then appointed, normally by signing a standard building contract completed by us on your behalf, and work on site commences.</p>
<p>	We can administer that contract to ensure that the contractor is performing his duties as agreed in terms of quality and keeping on programme.  A critical area is the control of expenditure where we ensure that money paid to the contractor at agreed stages is reflective of the work actually carried out.</p>
</Content>

      <div class="region region-content-bottom">
        <MenuOne active="what-we-do" />
        <MenuTwo active="building-control" items={[
          "../initial-designs",
          "../planning-approvals",
          "../building-control",
          "../site-operations",
          "../other-services"
        ]} />
      </div>

  </Layout>

  )
}
